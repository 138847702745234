<template>
  <div>
  <b-container class="[ pt-5 ][ animated fadeIn fast ][ container-dark container-dark-top ]">
    <b-row>
      
      <b-container>
<!-- Projects -->
          <div class="[ text-center text-lg-left ]">
            <h1>Projects</h1>
          </div>
<!-- Projects - Noroff year 1 -->                     
          <div class="[ ml-4 mr-2 mt-4 ]">
              <h2>2018, Noroff</h2>
              <div class="[ pl-2 pl-lg-5 ]">
                <ul class="[ container-dark-link ][ list-unstyled ]">
                    <b-media tag="li" class="[ m-2 ml-4 ]">
                        <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                        <a href="http://doristef.me/semester2/Portfolio1/csm/" target="_blank" alt="ReMake of First Semester Project, Semester 2, 2018"><b>Remake</b> of First Semester Project</a>
                    </b-media>
                    <b-media tag="li" class="[ m-2 ml-4 ]">
                        <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                        <a href="http://doristef.me/semester2/Portfolio1/RedPepperPizza/RedPepperPizza_NEW.pdf" target="_blank" alt="ReMake of MoodBoard, Semester 2, 2018"><b>PDF:</b> <b>Remake</b> of MoodBoard</a> 
                    </b-media>
                    <b-media tag="li" class="[ m-2 ml-4 ]">
                        <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                        <a href="http://doristef.me/semester2/Portfolio1/recycle/" target="_blank" alt="Remake, Re-Cycle Website, Semester 2, 2018"><b>Remake</b>, Re-Cycle Website</a>
                    </b-media>
                    <b-media tag="li" class="[ m-2 ml-4 ]">
                        <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                        <a href="http://doristef.me/HomeDecor3/shop.html" target="_blank" alt="Home Decor 3, Website / Shop Section, Semester 3, 2018">Home Decor, Website / Shop Section v2</a>
                    </b-media>
                    <b-media tag="li" class="[ m-2 ml-4 ]">
                        <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                        <a href="http://doristef.me/Semester_Project2" target="_blank" alt="Shields &amp; Snakes - BoardGame, Semester Project, Semester 3, 2018">Shields &amp; Snakes - JavaScript BoardGame, Semester Project</a>
                    </b-media>

                </ul>
            </div>
          </div>

      </b-container>
    </b-row>
  </b-container>
  <b-container class="[ pt-5 ][ animated fadeIn faster delay-1s ][ container-color ]">
    <b-row class="[ container-color-content ][ ml-3 ]">
      <b-container>

<!-- Projects - Noroff year 2 -->               
          <div class="[ ml-4 mr-2 mt-4 ][ container-color-content ]">
              <h2 class="[ pt-5 ]">2019, Noroff</h2>
              <div class="[ pl-2 pl-lg-5 ]">
                    <ul class="[ container-color-link ][ list-unstyled ]">
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/noroff-reactapp/#/" target="_blank" alt="Using Rick&amp;Morty API, Semester 4, 2019">Rick &amp; Morty API - Character Cards, React </a>
                        </b-media>
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/noroff-angularapp/" target="_blank" alt="Dashboard w/ multiple APIs, Semester 4, 2019">Dashboard - Multiple APIs, Angular --> Username: halldor, Password: doristef </a>
                        </b-media>
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/noroff-finalca/" target="_blank" alt="Card Game from API, Semester 4, 2019">Card Game - <b>Remake</b> from 1st semester, React --> Username: halldor, Password: doristef </a>
                        </b-media>
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/portfolio-spacex/" target="_blank" alt="SpaceX Dashboard, Semester 4, 2019">SpaceX Dashboard, Website <b>Remake</b> from 2nd semester, Vue</a>
                        </b-media>
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/portfolio2/redo.pdf" target="_blank" alt="Website Style Guide, Semester 4, 2019"><b>PDF:</b> Website Style Guide 2.0, <b>Remake</b> from 3rd semester</a>
                        </b-media>
                        <b-media tag="li" class="[ m-2 ml-4 ]">
                            <font-awesome-icon icon="angle-double-right" size="1x" class="[ mr-2 ]" />
                            <a href="http://doristef.me/semester4/FinalProject/" target="_blank" alt="Final Project, Semester 4, 2019"><b>Final Project, Vue  --> Username: doristef, Password: 1234 </b></a>
                        </b-media>
                    </ul>
                </div>
          </div>

      </b-container>
    </b-row>
  </b-container>

  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    
  name: 'Projects',
  components: { FontAwesomeIcon },
}
</script>